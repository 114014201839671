import {Heading, Button, Box, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {Main} from '../_shared/Layout/Main';
import {ArrowLeft} from 'react-feather';
import {KhsLink} from '../_shared/KhsLink';
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useRouter} from 'next/router';

export const Custom404Content = () => {
  const appInsights = useAppInsightsContext();
  const {t} = useTranslation();
  const router = useRouter();

  const onBackButtonClick = () => {
    router.back();
  };

  appInsights.trackPageView({name: 'Error', properties: {statusCode: 404}});

  return (
    <Main>
      <Box data-cy="404" mt={20} mb={140} as="section">
        <Heading variant="h1">{t('404.title')}</Heading>
        <Text>{t('404.subtext')}</Text>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Button
            data-cy="404BackButton"
            onClick={onBackButtonClick}
            mr={3}
            fontWeight="medium"
            color="white">
            <ArrowLeft size={24} />
            <Text ml={2} mb={0}>
              {t('404.gobackbutton')}
            </Text>
          </Button>
          {t('404.linkprefix')}
          <KhsLink
            data-cy="404link"
            ml={1}
            title={t('404.linktext')}
            href="/"
          />
        </Box>
      </Box>
    </Main>
  );
};
