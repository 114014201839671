import getConfig from 'next/config';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {languageSelector} from '../lib/context/selectors';
import {fapper} from '../utils/fapper';
import {ContentResponse, HeaderContent} from './types';

const {publicRuntimeConfig} = getConfig();

const getHeaderContent = async (
  tagParams: string[],
  language: string,
): Promise<HeaderContent> => {
  const result: ContentResponse<HeaderContent> = await fapper.get(
    // prettier-ignore
    `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}?culture=${language}&tags=${tagParams.join(';')}&type=header`,
  );
  return result.contentItems[0];
};

const useHeaderContent = (
  tagParams: string[],
  options: UseQueryOptions<HeaderContent, unknown, HeaderContent, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  const language = useSelector(languageSelector);
  return useQuery<HeaderContent>(
    ['HeaderContent', tagParams],
    () => getHeaderContent(tagParams, language!!),
    options,
  );
};

export {useHeaderContent, getHeaderContent};
