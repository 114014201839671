import React from 'react';
import parse from 'html-react-parser';
import {KhsLink} from '../components/_shared/KhsLink';
import {Image, Link as ChakraLink, CSSObject} from '@chakra-ui/react';
import Link from 'next/link';
import {isInternalLink} from './isInternalLink';

export const parseHtmlString = (
  value: string,
  lazyLoading: boolean,
  clickHandler?: () => void,
  customLinkStyles?: CSSObject,
) => {
  const ReplaceLink = (domNode: any) => {
    if (domNode.name === 'img') {
      return (
        <Image
          sizes={domNode.attribs.sizes}
          crossOrigin={domNode.attribs.crossorigin}
          referrerPolicy={domNode.attribs.referrerpolicy}
          loading={lazyLoading ? 'lazy' : 'eager'}
          src={domNode.attribs.src}
          height={domNode.attribs.height}
          width={domNode.attribs.width}
          alt={domNode.attribs.alt ?? ''}
        />
      );
    }
    if (domNode.name === 'a') {
      if (domNode.attribs.href.includes('mailto:')) {
        return domNode;
      }

      return isInternalLink(domNode.attribs.href)
        ? domNode.children.length > 0 && (
            <Link href={domNode.attribs.href} passHref>
              <ChakraLink
                role="group"
                sx={{
                  textDecoration: 'underline',
                }}>
                {domNode.children[0].data}
              </ChakraLink>
            </Link>
          )
        : domNode.children.length > 0 && (
            <KhsLink
              href={domNode.attribs.href}
              title={domNode.children[0].data}
              isExternal
              onClick={clickHandler}
              textDecoration="none"
              fontWeight="normal"
              sx={customLinkStyles}
            />
          );
    }
    return null;
  };

  return parse(value, {replace: ReplaceLink});
};
