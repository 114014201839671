import {Box} from '@chakra-ui/react';
import getConfig from 'next/config';
import {useRouter} from 'next/router';
import React, {ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {i18n} from '../../providers/i18n/i18n';
import {fapper} from '../../utils/fapper';
import {setLanguage, addTag, setCustomer} from './actionCreators';
import {Action, Tag} from './types';

const {publicRuntimeConfig} = getConfig();

export const InitialStateLoader = ({children}: {children: ReactNode}) => {
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const dispatch: Dispatch<Action> = useDispatch();

  useEffect(() => {
    const loadInitialState = async () => {
      try {
        dispatch(setLanguage(i18n.language.slice(0, 2)));
      } catch (e) {
        console.log(e);
      }
    };

    const loadTemplateState = async () => {
      try {
        const templateStateResults = await fapper.get(
          `${publicRuntimeConfig.NEXT_PUBLIC_CDN_URL}${publicRuntimeConfig.NEXT_PUBLIC_ENVIRONMENT_TEMPLATE_FILENAME}`,
        );
        if (templateStateResults.initialState?.length > 0) {
          templateStateResults.initialState.forEach((tag: Tag) => {
            dispatch(addTag(tag));
          });
        }
        setIsLoading(false);
      } catch (e) {
        console.log('error loading template state ', e);
      }
    };

    loadInitialState();
    loadTemplateState();
  }, [dispatch]);

  useEffect(() => {
    const loadCustomer = () => {
      // if customer exists as query param and is not an array, then update. Otherwise use default from .json config files
      if (router?.query?.customer && !Array.isArray(router.query.customer)) {
        dispatch(setCustomer(router.query.customer));
      }
    };

    if (!isLoading) {
      loadCustomer();
    }
  }, [dispatch, isLoading, router?.query]);

  return <>{isLoading ? <Box>Loading initial state..</Box> : children}</>;
};
