import React from 'react';
import {Box, Heading} from '@chakra-ui/react';

interface Props {
  title: string;
  children: React.ReactNode;
}
export const ServiceContentStep = ({title, children}: Props) => {
  return (
    <Box mb={[12, 0]}>
      <Heading variant="h2" mb="6">
        {title}
      </Heading>
      <Box>{children}</Box>
    </Box>
  );
};
