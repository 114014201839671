import {Intent, Tag} from '../lib/context/types';

export const linkState = (id: number, score: number, intent: Intent) => {
  return {
    axis: 'state',
    label: 'intent',
    intent: intent,
    value: id,
    score: score,
    single: false,
    mutable: true,
    removable: true,
  } as Tag;
};
