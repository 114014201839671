import {Button, Center} from '@chakra-ui/react';
import router from 'next/router';
import {trackEvent} from '../../../utils/tracking';
import {ArrowLeft} from '../Icon/Icons/ArrowLeft';

type GaProps = {
  type: string;
  title: string;
};
export interface Props {
  dataCy?: string;
  gaProps: GaProps;
}
export const BackButton = ({dataCy, gaProps}: Props) => {
  const onBackButtonClick = () => {
    trackEvent('BackButton', gaProps.type, gaProps.title);

    // if user land on page by deeplink, window.history has a length of 2
    // so navigate to root of website. Otherwise use the back functionality
    if (window.history.length === 2) {
      router.push('/');
    } else {
      router.back();
    }
  };
  return (
    <Button
      bg="secondaryBackground"
      width={14}
      height={14}
      borderRadius="12"
      flexShrink="0"
      onClick={onBackButtonClick}
      data-cy={dataCy}>
      <Center>
        <ArrowLeft
          width="24"
          height="24"
          color="secondaryBackgroundIconColor"
        />
      </Center>
    </Button>
  );
};
