import getConfig from 'next/config';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {Service} from '../components/Service';
import {languageSelector} from '../lib/context/selectors';
import {fapper, FapperError} from '../utils/fapper';

export interface ServiceResponseSlug {
  contentItem: Service;
}
export interface ServiceResponseTag {
  contentItems: Service[];
}

const {publicRuntimeConfig} = getConfig();

const getServiceContent = async (
  search: {id?: string; tags?: string[]},
  language: string,
): Promise<Service> => {
  if (search.id) {
    const result: ServiceResponseSlug = await fapper.get(
      `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}?culture=${language}&slug=${search.id}&type=service`,
    );
    return result.contentItem;
  } else {
    const result: ServiceResponseTag = await fapper.get(
      `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}?culture=${language}&tags=${search.tags}&type=service`,
    );
    return result.contentItems[0];
  }
};

const useServiceContent = (
  search: {id?: string; tags?: string[]},
  options: UseQueryOptions<Service, FapperError, Service, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  let queryId = '';
  if (search.id && search.tags) {
    console.error('Either provide id or tags, but not both');
  }
  if (search.id) {
    queryId = search.id;
  } else if (search.tags) {
    queryId = search.tags.join(',');
  }
  const language = useSelector(languageSelector);
  return useQuery<Service, FapperError>(
    ['ServiceContent', queryId],
    () => getServiceContent(search, language!!),
    options,
  );
};

export {useServiceContent, getServiceContent};
