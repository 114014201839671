import {useCustomerTag} from '../hooks/useCustomerTag';
import {useTriggerTag} from '../hooks/useTriggerTag';

export const useContentConfig = () => {
  const customer = useCustomerTag();
  const trigger = useTriggerTag();

  const config: string[] = [customer, trigger];
  return config;
};
