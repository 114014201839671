import {useTheme} from '@chakra-ui/react';
import {DefaultTheme} from '../../theme/themes/default';
import QRCode from 'react-qr-code';

export interface QrCodeProps {
  value: string;
  size: number;
  style?: string;
}

export const QrCode = ({value, size, style}: QrCodeProps) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <QRCode
      value={value}
      size={size}
      bgColor={theme.colors.white}
      fgColor={theme.colors.black}
      className={style}
    />
  );
};
