import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {Box, Center, Spinner} from '@chakra-ui/react';
import {useServiceContent} from '../../api/getService';
import {useCustomerTag} from '../../hooks/useCustomerTag';
import {useTriggerTag} from '../../hooks/useTriggerTag';
import {ServiceIndividualView} from './ServiceIndividualView';
import {addOrUpdateIntent} from '../../lib/context/actionCreators';
import {Action} from '../../lib/context/types';
import {linkState} from '../../utils/linkState';
import {Service} from '../Service';
import {useEffect} from 'react';
import {Custom404Content} from '../Custom404Content';

export interface ServiceIndividualControllerProps {
  id?: string;
  tags?: string[];
  individualItem?: boolean;
  lazyLoading: boolean;
}

export const ServiceIndividualController = ({
  id,
  tags,
  individualItem = false,
  lazyLoading,
}: ServiceIndividualControllerProps) => {
  const customer = useCustomerTag();
  const trigger = useTriggerTag();
  const appInsights = useAppInsightsContext();
  const templateTags = [customer, trigger];
  const defaultTags = tags || [];
  const configTags = [...templateTags, ...defaultTags];
  const dispatch: Dispatch<Action> = useDispatch();

  if (individualItem && !tags) {
    console.error(
      'Tags must be specified when using component as an individual item',
    );
  }

  const {data, isLoading, isFetching, error} = useServiceContent(
    individualItem ? {tags: configTags} : {id},
  );

  useEffect(() => {
    if (data) {
      dispatch(addOrUpdateIntent(linkState(data.id, 100, 'decision')));
    }
  }, [data, dispatch]);

  const trackServiceContinueWithoutCodeOverview = useTrackEvent(
    appInsights,
    'Continue service without code on overview',
    {serviceId: data?.id},
  );

  const trackServiceContentLinkClickedOnOverview = useTrackEvent(
    appInsights,
    'Service content link clicked on overview',
    {serviceId: data?.id},
  );

  const onClickContentLink = (serviceData: Service) => () => {
    dispatch(addOrUpdateIntent(linkState(serviceData.id, 50, 'action')));
    trackServiceContentLinkClickedOnOverview({serviceId: serviceData?.id});
  };

  const onClickContinueWithoutCode = (serviceData: Service) => () => {
    dispatch(addOrUpdateIntent(linkState(serviceData.id, 80, 'action')));
    trackServiceContinueWithoutCodeOverview({serviceId: serviceData?.id});
  };

  if (error) {
    if (error.status === 404) {
      return <Custom404Content />;
    }
  }

  return (
    <Box as="section" mb={[12, null, 14]} pb={[8, 7]}>
      {isLoading || isFetching ? (
        <Center>
          <Spinner
            mt={10}
            emptyColor="gray.200"
            thickness="4px"
            speed="0.7s"
            size="xl"
            color="primary"
          />
        </Center>
      ) : data ? (
        <ServiceIndividualView
          lazyLoading={lazyLoading}
          onClickContentLink={onClickContentLink(data)}
          onClickContinueWithoutCode={onClickContinueWithoutCode(data)}
          {...data}
        />
      ) : null}
    </Box>
  );
};
