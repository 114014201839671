import getConfig from 'next/config';
import ReactGA from 'react-ga';
import {appInsights} from '../monitoring/appInsights';

const {publicRuntimeConfig} = getConfig();

const isTrackingEnabled = () => {
  return publicRuntimeConfig.NEXT_PUBLIC_ENABLE_TRACKING === 'true';
};

export const trackingInitializer = () => {
  if (isTrackingEnabled()) {
    ReactGA.initialize(publicRuntimeConfig.NEXT_PUBLIC_GA);
    ReactGA.pageview(window.location.pathname);
  }
};

export const trackPage = (page: string) => {
  if (isTrackingEnabled()) {
    const trackUrl = `${window.location.hostname}${page}${window.location.search}`;
    if (publicRuntimeConfig.NEXT_PUBLIC_DEBUG === 'true') {
      console.debug('trackpage', trackUrl);
    }
    ReactGA.pageview(trackUrl);
    appInsights.trackPageView({uri: trackUrl});
  }
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string,
) => {
  if (isTrackingEnabled()) {
    const event = {
      category,
      action,
      label,
      dimension1: window.location.hostname,
    };
    if (publicRuntimeConfig.NEXT_PUBLIC_DEBUG === 'true') {
      console.debug('trackEvent', event);
    }

    ReactGA.event(event);
    appInsights.trackEvent({
      name: category,
      properties: {
        host: window.location.hostname,
        action,
        label,
      },
    });
  }
};
