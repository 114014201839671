import {Text, Box} from '@chakra-ui/react';

interface Props {
  text: string;
}
export const StripeThroughText = ({text}: Props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      marginY="7"
      _before={{
        content: `""`,
        borderBottom: '1px solid',
        borderColor: 'dividerColor',
        flex: 1,
      }}
      _after={{
        content: `""`,
        borderBottom: '1px solid',
        borderColor: 'dividerColor',
        flex: 1,
      }}
      sx={{
        lineHeight: '0.1em',
      }}>
      <Text as="span" paddingX="1.5">
        {text}
      </Text>
    </Box>
  );
};
