import {Box} from '@chakra-ui/layout';
import React from 'react';
import {HeaderView} from './HeaderView';
import {useHeaderContent} from '../../api/getHeaderContent';
import {Spinner, Center} from '@chakra-ui/react';
import {Dispatch} from 'redux';
import {Action} from '../../lib/context/types';
import {changeLanguage} from '../../lib/context/actionCreators';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useDispatch} from 'react-redux';
import {useContentConfig} from '../../utils/useContentConfig';

interface Props {
  lazyLoading: boolean;
}

export const HeaderController = ({lazyLoading}: Props) => {
  const config = useContentConfig();
  const {data, isLoading, isFetching, refetch} = useHeaderContent(config);
  useLanguageChange(refetch);
  const dispatch: Dispatch<Action> = useDispatch();

  const handleLanguageSelect = (value: string) => {
    dispatch(changeLanguage(value));
  };

  return (
    <Box as="header">
      {isLoading || isFetching ? (
        <Center>
          <Spinner
            mt={10}
            emptyColor="gray.200"
            thickness="4px"
            speed="0.7s"
            size="xl"
            color="primary"
          />
        </Center>
      ) : data ? (
        <HeaderView
          title={data.title}
          lazyLoading={lazyLoading}
          imageURL={data.imageUrl}
          handleLanguageSelect={handleLanguageSelect}
        />
      ) : null}
    </Box>
  );
};
