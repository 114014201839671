import {Service} from '../Service';
import {Box, Image, Heading, Flex} from '@chakra-ui/react';
import {Main} from '../_shared/Layout/Main';
import {parseHtmlString} from '../../utils/parseHtmlString';
import {DefaultContentSteps} from '../ServicesContentSteps/DefaultContentSteps';
import {BackButton} from '../_shared/BackButton';

export interface Props extends Service {
  onClickContentLink: () => void;
  onClickContinueWithoutCode: () => void;
  lazyLoading: boolean;
}

export const ServiceIndividualView = ({
  title,
  imageUrl,
  imageAlt = '',
  description,
  steps,
  onClickContentLink,
  onClickContinueWithoutCode,
  lazyLoading,
}: Props) => {
  const customLinkStyles = {
    textColor: 'visualListColor',
  };
  const showGobackButton = () => {
    return window.history.length > 2;
  };
  return (
    <>
      <Image
        loading={lazyLoading ? 'lazy' : 'eager'}
        src={imageUrl}
        alt={imageAlt}
        objectFit="cover"
        w="100%"
        h={[200, 240]}
      />
      <Box bg="primaryBackground" mb="10">
        <Main
          py={10}
          position="relative"
          _before={{
            content: `""`,
            position: 'absolute',
            width: '84px',
            height: '40px',
            left: '18px',
            bottom: '0',
            background: 'primaryBackground',
            borderRadius: '8px',
            transform: 'rotate(-45deg)',
          }}>
          <Flex
            alignItems="baseline"
            flexDirection={['column', null, 'row']}
            transform={[
              null,
              null,
              null,
              null,
              showGobackButton() ? 'translateX(-80px)' : 'translateX(0)',
            ]}>
            {showGobackButton() && (
              <Box mb={7}>
                <BackButton
                  dataCy="serviceBackButton"
                  gaProps={{type: 'service', title}}
                />
              </Box>
            )}
            <Box ms={showGobackButton() ? [0, null, 6] : 0}>
              <Heading as="h1" variant="h1" color="white" mb="7">
                {title}
              </Heading>
              <Box textStyle="intro" color="white">
                {parseHtmlString(
                  description || '',
                  lazyLoading,
                  onClickContentLink,
                  customLinkStyles,
                )}
              </Box>
            </Box>
          </Flex>
        </Main>
      </Box>
      <Main>
        <Box>
          <DefaultContentSteps
            lazyLoading={lazyLoading}
            steps={steps}
            onClickContinueWithoutCode={onClickContinueWithoutCode}
          />
        </Box>
      </Main>
    </>
  );
};
