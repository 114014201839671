import {Box, Button, Link as ChakraLink, Flex} from '@chakra-ui/react';
import {ServiceContentStepContainer} from '../ServiceContentStepContainer';
import {ServiceContentStep} from '../ServiceContentStep';
import {parseHtmlString} from '../../../utils/parseHtmlString';
import React from 'react';
import {QrCode} from '../../QrCode';
import {useTranslation} from 'react-i18next';
import Link from 'next/link';
import {Icon} from '../../_shared/Icon';
import {StripeThroughText} from '../../_shared/StripeThroughText';

type StepContentType = 'richText' | 'qrCode';

type StepContentProp = {
  type: StepContentType;
  text: string;
};

export type StepProp = {
  title: string;
  items: StepContentProp[];
};

export interface DefaultContentStepsProps {
  steps: StepProp[];
  onClickContinueWithoutCode: () => void;
  lazyLoading: boolean;
}

export const DefaultContentSteps = ({
  steps,
  onClickContinueWithoutCode,
  lazyLoading,
}: DefaultContentStepsProps) => {
  const {t} = useTranslation();

  const RenderStepContent = (content: StepContentProp) => {
    switch (content.type) {
      case 'richText':
        return (
          <Box
            sx={{
              whiteSpace: 'pre-line',
              mb: 3,
            }}
            pr={[null, 10]}>
            {parseHtmlString(content.text, lazyLoading)}
          </Box>
        );
      case 'qrCode':
        return (
          <Box mb={3}>
            <Flex>
              <QrCode value={content.text} size={180} />
            </Flex>
            <Box width="180px">
              <StripeThroughText text="of" />

              <Link href={content.text} passHref>
                <ChakraLink
                  role="group"
                  lineHeight="24px"
                  isExternal={true}
                  textDecoration="none"
                  fontWeight="400"
                  textColor="primaryButtonColor"
                  _hover={{
                    textDecoration: 'none',
                  }}>
                  <Button
                    width="100%"
                    onClick={onClickContinueWithoutCode}
                    rightIcon={
                      <Icon
                        name="arrow_right"
                        width="18"
                        height="18"
                        color="primaryButtonColor"
                      />
                    }>
                    {t('services.continue_without_code')}
                  </Button>
                </ChakraLink>
              </Link>
            </Box>
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <ServiceContentStepContainer>
      {steps &&
        steps.map((step, index) => (
          <Box key={index}>
            <ServiceContentStep title={step.title}>
              {step.items?.map((item, i) => (
                <RenderStepContent key={i} {...item} />
              ))}
            </ServiceContentStep>
          </Box>
        ))}
    </ServiceContentStepContainer>
  );
};
